//
// Sidebar Controller
//

import { onClick } from '../utilities'

export default class SidebarController {
  public static readonly shared = new SidebarController()

  // MARK: - Object Lifecycle

  private constructor() {
    onClick('#left-sidebar', this.toggleLeftSidebar.bind(this), false)
    onClick('.left-sidebar-close,.left-sidebar-toggle,.wcpf-button-action-reset', this.toggleLeftSidebar.bind(this))
  }

  // MARK: - Actions

  private toggleLeftSidebar(_element: Element) {
    const sidebarElement = document.getElementById('left-sidebar')
    if (!sidebarElement || sidebarElement.offsetParent != null) return

    sidebarElement.classList.toggle('sidebar--open')
  }
}
