//
// Utilities
//

// MARK: - Objects

export function dig(object: any, ...keys: string[]): any {
  let value = object

  for (const key of keys) {
    if (typeof value !== 'object' || value === null) return null

    value = value[key]
  }

  return value
}

// MARK: - Attributes

export function getElementString(element: Element, attribute: string): string | null {
  const value = element.getAttribute(attribute)
  if (typeof value !== 'string' || value.length < 1) return null

  return value
}

export function getElementList(element: Element, attribute: string): string[] {
  const value = element.getAttribute(attribute)
  if (typeof value !== 'string') return []

  return value.split(',').map((item) => item.trim())
}

export function getElementBoolean(element: Element, attribute: string): boolean | null {
  const value = element.getAttribute(attribute)
  if (typeof value !== 'string' || value.length < 1) return null

  return value === '1' || value === 'true'
}

export function getElementInteger(element: Element, attribute: string): number | null {
  const value = element.getAttribute(attribute)
  if (typeof value !== 'string' || value.length < 1) return null

  const parsed = parseInt(value)
  if (isNaN(parsed)) return null

  return parsed
}

export function getElementFloat(element: Element, attribute: string): number | null {
  const value = element.getAttribute(attribute)
  if (typeof value !== 'string' || value.length < 1) return null

  const parsed = parseFloat(value)
  if (isNaN(parsed)) return null

  return parsed
}

export function setElementAttribute(
  element: Element,
  attribute: string,
  value: boolean | number | string | string[] | null
): void {
  if (typeof value === 'boolean') {
    element.setAttribute(attribute, value ? 'true' : 'false')
  } else if (typeof value === 'number') {
    element.setAttribute(attribute, value.toString())
  } else if (typeof value === 'string' && value.length > 0) {
    element.setAttribute(attribute, value)
  } else if (Array.isArray(value) && value.length > 0) {
    element.setAttribute(attribute, value.join(','))
  } else {
    element.removeAttribute(attribute)
  }
}

// MARK: - Element Visibility

export function getElementVisible(element: Element): boolean {
  return element.hasAttribute('hidden')
}

export function setElementVisible(element: Element, visible: boolean): void {
  if (visible) {
    element.removeAttribute('hidden')
    element.removeAttribute('aria-hidden')
  } else {
    element.setAttribute('hidden', 'hidden')
    element.setAttribute('aria-hidden', 'true')
  }
}

// MARK: - Element Accessibility

export function getElementSelected(element: Element): boolean {
  return element.getAttribute('aria-selected') === 'true'
}

export function setElementSelected(element: Element, selected: boolean): void {
  element.setAttribute('aria-selected', selected ? 'true' : 'false')
}

// MARK: - Events

export function onChange(selector: string, callback: ((element: Element) => void) | null): void {
  document.addEventListener('change', (event) => {
    const element = event.target
    if (element instanceof Element === false || element.matches(selector) === false) return

    event.preventDefault()
    event.stopImmediatePropagation()
    if (callback) callback(element)
  })
}

export function onClick(
  selector: string,
  callback: ((element: Element) => void) | null,
  scanAncestors: boolean = true
): void {
  document.addEventListener('click', (event) => {
    let element = event.target
    if (element instanceof Element === false) return

    if (scanAncestors) {
      element = element.closest(selector)
    } else {
      element = element.matches(selector) ? element : null
    }

    if (element instanceof Element === false) return

    event.preventDefault()
    event.stopImmediatePropagation()
    if (callback) callback(element)
  })
}

export function onSubmit(selector: string, callback: ((element: Element) => void) | null): void {
  document.addEventListener('submit', (event) => {
    let element = event.target
    if (element instanceof Element === false) return

    element = element.closest(selector)
    if (element instanceof Element === false) return

    event.preventDefault()
    event.stopImmediatePropagation()
    if (callback) callback(element)
  })
}

export function onLoaded(callback: () => void): void {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback, { once: true })
  } else {
    callback()
  }
}

export function onResize(callback: () => void): void {
  window.addEventListener('resize', callback)
}

export function onScroll(callback: () => void): void {
  window.addEventListener('scroll', callback)
}

// MARK: - Element Size

export function computeSize(element: HTMLElement) {
  const { width } = getComputedStyle(element)

  // We need to compute the height of the element, but we don't want this to be visible to the user. To do this, we
  // temporarily force the element's style to be fixed and hidden. Later, we restore the element's original styles.
  const properties = new Map<string, string>()
  properties.set('position', 'fixed')
  properties.set('top', '0')
  properties.set('left', '0')
  properties.set('visibility', 'hidden')
  properties.set('opacity', '0')
  properties.set('width', width)
  properties.set('height', 'auto')

  for (const [property, value] of properties) {
    const previousValue = element.style.getPropertyValue(property)
    element.style.setProperty(property, value)
    properties.set(property, previousValue)
  }

  const { height } = getComputedStyle(element)

  for (const [property, value] of properties) {
    if (value.length > 0) {
      element.style.setProperty(property, value)
    } else {
      element.style.removeProperty(property)
    }
  }

  // We don't need the result of this computation, but we need to ensure the element's layout and style have been
  // restored which can be done by forcing a reflow. The `getComputedStyle` function is one way to do this. For more
  // information on reflows, read the following article: https://gist.github.com/paulirish/5d52fb081b3570c81e3a
  const _ = getComputedStyle(element)

  return { width, height }
}

// MARK: - Scrolling

export function scrollToYOffset(offset: number, callback: () => void): void {
  const top = Math.trunc(offset)
  const didScroll = () => {
    if (top !== Math.trunc(window.pageYOffset)) return

    window.removeEventListener('scroll', didScroll)
    callback()
  }

  window.addEventListener('scroll', didScroll)
  window.scrollTo({ top, behavior: 'smooth' })
  didScroll()
}
